import React from 'react';

const WhatsAppButton = () => {
  const phone = '5511962573377';
  const message = 'Olá! Gostaria de falar com você.';
  const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',
        color: '#fff',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
        zIndex: 1000,
        cursor: 'pointer',
        textDecoration: 'none',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 32 32"
        fill="white"
      >
        <path d="M16.002 2.75c-7.29 0-13.25 5.96-13.25 13.25 0 2.34.614 4.63 1.78 6.63L2 30l7.57-2.49c1.87 1.02 3.97 1.56 6.18 1.56h.01c7.29 0 13.25-5.96 13.25-13.25s-5.96-13.25-13.25-13.25zm.008 23.74h-.01c-1.83 0-3.62-.49-5.2-1.41l-.37-.22-4.49 1.48 1.47-4.37-.24-.38c-1.09-1.71-1.66-3.69-1.66-5.71 0-5.91 4.81-10.72 10.73-10.72 2.87 0 5.56 1.12 7.58 3.14 2.02 2.02 3.13 4.7 3.13 7.57 0 5.91-4.81 10.72-10.73 10.72zm5.9-7.91c-.31-.16-1.82-.9-2.1-1s-.48-.16-.68.17-.78.99-.95 1.2-.35.24-.65.08c-.31-.16-1.3-.48-2.48-1.53-.91-.81-1.52-1.8-1.7-2.1s-.02-.46.14-.62c.14-.14.31-.35.46-.53.15-.19.2-.31.31-.52.1-.2.05-.38-.02-.54-.08-.16-.68-1.64-.93-2.25-.24-.56-.49-.48-.68-.49-.18 0-.38-.01-.59-.01s-.54.08-.82.38c-.28.3-1.08 1.05-1.08 2.56s1.1 2.96 1.26 3.17c.16.21 2.15 3.28 5.21 4.6.73.32 1.3.5 1.74.63.73.23 1.4.2 1.93.12.59-.09 1.82-.75 2.08-1.48.26-.73.26-1.36.18-1.48-.07-.12-.28-.2-.59-.36z" />
      </svg>
    </a>
  );
};

export default WhatsAppButton;